'use client'

import { cls, whisper } from '@/utils'
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import IconChevronLeft from '@haiper/icons-svg/icons/outline/chevron-left.svg'
import IconChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import Button from '@/components/button'
import { nanoid } from 'nanoid'

export interface ScrollableProps {
  className?: string
  direction?: 'horizontal' | 'vertical'
  autoScroll?: boolean
}

export const getAverageWidthInContainer = (container: HTMLDivElement) => {
  return container.children.length ? container.scrollWidth / container.children.length : null
}

export default function Scrollable({
  className,
  direction = 'horizontal',
  autoScroll,
  children,
}: PropsWithChildren<ScrollableProps>) {
  const ref = useRef<HTMLDivElement | null>(null)

  const scrollRef = useRef<HTMLDivElement | null>(null)
  const scrollLeft = useCallback(() => {
    if (scrollRef.current) {
      const averageWidth = getAverageWidthInContainer(scrollRef.current) ?? 100
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft - averageWidth,
        behavior: 'smooth',
      })
    }
  }, [])

  const scrollRight = useCallback(() => {
    if (scrollRef.current) {
      const averageWidth = getAverageWidthInContainer(scrollRef.current) ?? 100
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft + averageWidth,
        behavior: 'smooth',
      })
    }
  }, [])

  const [scrollKey, setScrollKey] = useState(nanoid())

  useEffect(() => {
    setTimeout(() => {
      setScrollKey(nanoid())
    }, 100)
  }, [children])

  const handleScroll = useCallback(() => {
    setScrollKey(nanoid())
  }, [])

  const canScrollLeft = useMemo(() => {
    if (!scrollKey) {
      return false
    }
    return scrollRef.current && scrollRef.current?.scrollLeft > 0
  }, [scrollKey])

  const canScrollRight = useMemo(() => {
    if (!scrollKey) {
      return false
    }
    return (
      scrollRef.current &&
      scrollRef.current?.scrollLeft < scrollRef.current?.scrollWidth - scrollRef.current?.clientWidth - 2
    )
  }, [scrollKey])

  const buttonContainerStyle = cls('absolute inset-y-0 flex items-center pointer-events-none')
  const buttonStyle = cls('size-10 shrink-0 bg-surface rounded-full border border-border pointer-events-auto p-0')
  const iconStyle = 'size-6 text-icon'

  const lastActiveItemRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    let observer: MutationObserver | null = null
    setTimeout(() => {
      if (autoScroll && ref.current) {
        observer = new MutationObserver(() => {
          const container = scrollRef.current
          if (!container) {
            return
          }
          const activeChild = container.querySelector('[data-active="true"]')
          if (activeChild && lastActiveItemRef.current !== activeChild) {
            lastActiveItemRef.current = activeChild as HTMLDivElement

            const activeItemLeft = (activeChild as HTMLDivElement).offsetLeft
            const viewPortWidth = container.clientWidth
            const scrollLeft = Math.min(
              Math.max(0, activeItemLeft + activeChild.clientWidth / 2 - viewPortWidth / 2),
              container.scrollWidth - viewPortWidth,
            )

            container.scrollTo({
              left: scrollLeft,
              behavior: 'smooth',
            })
          }
        })
        observer.observe(ref.current, { childList: true, subtree: true })
      }
    }, 100)

    return () => {
      observer?.disconnect()
    }
  }, [autoScroll])

  return (
    <div ref={ref} className='relative w-full' aria-label='scrollable container'>
      <div
        ref={scrollRef}
        className={cls(
          'flex no-scrollbar',
          direction === 'horizontal' ? 'overflow-x-auto flex-row' : 'overflow-y-auto flex-col',
          className,
        )}
        onScroll={handleScroll}
      >
        {children}
      </div>
      {/* previous */}
      <div className={cls(buttonContainerStyle, 'left-5 md:left-[-20px]')}>
        <Button
          key={scrollKey}
          variant='outline'
          className={cls(buttonStyle, canScrollLeft ? '' : 'hidden')}
          disabled={!canScrollLeft}
          onClick={scrollLeft}
        >
          <IconChevronLeft className={iconStyle} />
        </Button>
      </div>
      {/* next */}
      <div className={cls(buttonContainerStyle, 'right-0 md:right-[-20px]')}>
        <Button
          key={scrollKey}
          variant='outline'
          className={cls(buttonStyle, canScrollRight ? '' : 'hidden')}
          disabled={!canScrollRight}
          onClick={scrollRight}
        >
          <IconChevronRight className={iconStyle} />
        </Button>
      </div>
    </div>
  )
}

import { cls, isVideoUrl } from '@/utils'
import { useMemo } from 'react'
import Video from '@/components/video'
import Image from '@/components/image'

export interface MultiMediaProps {
  media_url: string
  thumbnail_url: string
  className?: string
  playClassName?: string
  autoPlay?: boolean
  onClick?: (e?: any) => void
}

export default function MultiMedia({
  media_url,
  thumbnail_url,
  className,
  autoPlay,
  playClassName,
  onClick,
}: MultiMediaProps) {
  const isVideo = useMemo(() => isVideoUrl(media_url), [media_url])
  const baseClassName = 'w-full aspect-video object-scale-down rounded-md'

  if (isVideo) {
    return (
      <Video
        playOnHover
        loop
        playsInline
        autoPlay={autoPlay}
        controls={false}
        src={media_url}
        poster={thumbnail_url ?? undefined}
        className={cls(baseClassName, className)}
        playClassName={playClassName}
        onClick={onClick}
      />
    )
  }
  return <Image src={media_url} alt='media' className={cls(baseClassName, className)} onClick={onClick} />
}

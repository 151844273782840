import { useCallback } from 'react'
import usePopups from './usePopups'
import useNavigation from './useNavigation'

export default function useEventAction(data: haiper.MarketingEvent | null) {
  const action = data?.action ?? null
  const navigation = useNavigation()
  const { openTemplateOfTheDay } = usePopups()

  const openCampignDialog = useCallback(() => {
    openTemplateOfTheDay()
  }, [openTemplateOfTheDay])

  const handleClick = useCallback(
    async (e: any) => {
      if (!action || action?.type === 'open_event') {
        navigation.push(`/event?evt=${data?.event_id}`)
      } else if (action?.type === 'open_url') {
        navigation.push(action.payload?.url)
      } else if (action?.type === 'popup_templates') {
        openCampignDialog()
      } else if (action?.type === 'custom') {
        await action?.payload?.onClick?.()
      }
    },
    [action, navigation, openCampignDialog, data],
  )

  return {
    handleClick,
  }
}
